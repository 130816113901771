import React from 'react'

import './screen.scss'

class Screen extends React.Component {
  render () {
    const idNameBase = this.props.screens.map(function (screen) { return screen.size === 'mobile' ? 'mob-' : (screen.size === 'tablet' ? 'tab-' : 'desk-') }).join('')

    // (this.props.desktopImg ? 'desk-' : '') + (this.props.tabletImg ? 'tab-' : '') + (this.props.mobileImg ? 'mob-' : '')

    const posts = this.props.screens.map(function (screen) {
      const fixedClass = screen.fixedAtSide ? 'screen__window-sidefixed' : 'screen__window-topfixed'
      const overlayID = screen.size === 'mobile' ? 1 : (screen.size === 'tablet' ? 2 : 3)
      return (<>
        <li className="screen__overlay" id={idNameBase + overlayID}></li>
        <li className="screen__window" id={idNameBase + screen.size[0]}><img src={screen.img} alt={`website on ${screen.size}`} /></li>
        {screen.imgFixed && <li className={fixedClass} id={idNameBase + screen.size[0]}><img src={screen.imgFixed} alt={`fixed part of the website on ${screen.size}`} /></li>}
      </>
      )
    })

    return (
      <div className="screen">
        <ul className="screen__wrapper">
          <li className="screen__overlay" id={`${idNameBase}4`}></li>
          {posts}
        </ul>
      </div>
    )
  }
}

export default Screen

// <li className="screen__overlay" id={`${idNameBase}3`}></li>
// {this.props.desktopImg && <li className="screen__window" id={`${idNameBase}d`}><img src={this.props.desktopImg} alt={'website on desktop'} /></li>}
// {this.props.desktopImgFixed && <li className={'screen__window-sidefixed'} id={`${idNameBase}d`}><img src={this.props.desktopImgFixed} alt={'website on desktop'} /></li>}
//
// {this.props.tabletImg && <li className="screen__overlay" id={`${idNameBase}2`}></li>}
// {this.props.tabletImg && <li className="screen__window" id={`${idNameBase}t`}><img src={this.props.tabletImg} alt={'website on tablet'} /></li>}
// {this.props.tabletImgFixed && <li className={'screen__window-sidefixed'} id={`${idNameBase}t`}><img src={this.props.tabletImgFixed} alt={'website on tablet'} /></li>}
//
// {this.props.mobileImg && <li className="screen__overlay" id={`${idNameBase}1`}></li>}
// {this.props.mobileImg && <li className="screen__window" id={`${idNameBase}m`}><img src={this.props.mobileImg} alt={'website on mobile'} /></li>}
// {this.props.mobileImgFixed && <li className={'screen__window-sidefixed'} id={`${idNameBase}m`}><img src={this.props.mobileImgFixed} alt={'website on mobile'} /></li>}
