import React from 'react'
import PropTypes from 'prop-types'

import './paragraph.scss'

class Paragraph extends React.Component {
  render () {
    return (
      <div className={`paragraph ${this.props.intro ? 'paragraph--intro' : ''} ${this.props.iframe ? 'paragraph--iframe' : ''}`}>
        {this.props.children}
      </div>
    )
  }
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired
}

export default Paragraph
