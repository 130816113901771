import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'

import './process.scss'

class Process extends React.Component {
  render () {
    return (
      <div className="process">
        <div className="process__wrapper">
          {this.props.children}
        </div>
      </div>
    )
  }
}

Process.propTypes = {
  children: PropTypes.node.isRequired
}

export default Process
