import React from 'react'
import PropTypes from 'prop-types'
import { StickyContainer, Sticky } from 'react-sticky'

class Process extends React.Component {
  render () {
    const typeClass = `process__step-${this.props.type || 'empty'}`

    return (
      <StickyContainer>
        <div className={`process__step ${typeClass}`}>
          <h2 className='process__step-title'>
            <Sticky topOffset={25 - 50 - 10} bottomOffset={-120}>
              {({ style, isSticky }) => (
                <span style={{
                  ...style,
                  transform: `scale(${isSticky ? '0.8' : '1'})`,
                  top: (isSticky ? `${50 + 10}px` : 0),
                  zIndex: (isSticky ? 720 : 630)
                }} />
              )}
            </Sticky>
            {this.props.title || 'The next step'}
          </h2>
          {this.props.children}
        </div>
      </StickyContainer>

    )
  }
}

Process.propTypes = {
  children: PropTypes.node.isRequired
}

export default Process
