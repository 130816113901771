import React from 'react'

import './image.scss'

class Image extends React.Component {
  render () {
    let altElement = false
    if (this.props.alt) {
      altElement = (<p>{this.props.alt}</p>)
    }

    let imgElement = this.props.children
    if (this.props.background) {
      imgElement = (<div className="blog__image--background">
        {this.props.children}
      </div>)
    }

    return (
      <div className="blog__image-wrapper">
        {imgElement}
        {altElement}
      </div>
    )
  }
}

class TwoImage extends React.Component {
  render () {
    return (
      <div className="blog__two-images">
        {this.props.children}
      </div>
    )
  }
}

export { Image, TwoImage }
