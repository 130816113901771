import React from 'react'

import './quote.scss'

class Quote extends React.Component {
  render () {
    return (
      <div className="blog__quote">
        {this.props.children}
      </div>
    )
  }
}

export default Quote
