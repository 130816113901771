import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import Layout from '../components/Layout/layout-single'
import List from '../components/Blocklist/list'
import Header from '../components/Header/header'
import ProcessBlogs from '../components/datacontent'
import SEO from '../components/SEO/seo'

import Quote from '../components/Blog/Quote/quote'
import { Image, TwoImage } from '../components/Blog/Image/image'
import Screen from '../components/Screen/screen'
import Paragraph from '../components/Project/Paragraph/paragraph'
import Process from '../components/Project/Process/process'
import Processstep from '../components/Project/Process/step'

const shortcodes = { Quote, Image, TwoImage, Screen, Paragraph, Process, Processstep, Link }

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.post ? this.props.data.post.edges[0].node : {}
    const { previous, next } = this.props.pageContext
    let headerElement = false
    let imageSeo = false

    if (!post.frontmatter) {
      return (
        <Layout location={this.props.location} type="blog">
          <></>
          <><p>Searching Post</p></>
        </Layout>
      )
    }

    if (post.frontmatter) {
      let featuredImgFluid = false
      if (post.frontmatter.overviewImg && post.frontmatter.overviewImg.childImageSharp) {
        featuredImgFluid = post.frontmatter.overviewImg.childImageSharp.fluid
      }
      headerElement = (
        <Header
          location={this.props.location}
          fluid={featuredImgFluid}
          alt={post.frontmatter.title}
          title={post.frontmatter.title}
        />
      )
      imageSeo = post.frontmatter.overviewImg.childImageSharp ? post.frontmatter.overviewImg.childImageSharp.fluid.src : false
    }

    const posts = this.props.data.allMdx.edges

    let blogclass = 'blog'
    let pathname = this.props.location.pathname.split('/')
    if (pathname && pathname.filter((i) => { return i.length > 0 }).length > 0) {
      pathname = pathname.filter((i) => { return i.length > 0 })[0]
      blogclass += ` blog--${pathname}`
    }

    return (
      <Layout location={this.props.location} type="blog">
        <>
          <SEO
            imagesrc={imageSeo} location={this.props.location}
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            typeOG="article"
          />
          {headerElement}
        </>
        <>
          <article className={blogclass}>
            <header>
              <h1>
                {post.frontmatter.title}
              </h1>
              <p>
                You-et / {post.frontmatter.date}{post.frontmatter.editedDate ? ` / edited on ${post.frontmatter.editedDate}` : ''}
              </p>
            </header>
            <MDXProvider components={shortcodes}><MDXRenderer>{post.body}</MDXRenderer></MDXProvider>
          </article>

          <nav className="blog-nav">
            <ul>
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </>
        <>
          <div className="layout__content">
            <h2>You might also like...</h2>
            <List items={ProcessBlogs(posts)} size="small" buttonLink="/blog" buttonText="Explore more blogs" />
          </div>
        </>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    post: allMdx (filter: {fields: { slug: { eq: $slug } }}, limit: 1) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          body
          frontmatter {
            title
            date(formatString: "ll")
            editedDate(formatString: "ll")
            description
            overviewImg {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allMdx(sort: {
      fields: [frontmatter___date], order: DESC },
      filter: {fields: { slug: { ne: $slug } }},
      limit: 3
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
  }
`
